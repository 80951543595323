gsap.registerPlugin(MotionPathPlugin, ScrollTrigger);

const windowWidth = window.innerWidth;

const animationInline = bodymovin.loadAnimation({
  container: document.getElementById("lottie"),
  autoplay: false,
  renderer: "svg",
  loop: false,
  animationData: {
    v: "5.8.1",
    fr: 25,
    ip: 353,
    op: 435,
    w: 1280,
    h: 720,
    nm: "Animation",
    ddd: 0,
    assets: [],
    layers: [
      {
        ddd: 0,
        ind: 1,
        ty: 3,
        nm: "Null 5",
        sr: 1,
        ks: {
          o: { a: 0, k: 0, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [640.25, 360.25, 0], ix: 2, l: 2 },
          a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
          s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
        },
        ao: 0,
        ip: 0,
        op: 2500,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 2,
        ty: 4,
        nm: "Hulf_Sircle 2",
        parent: 1,
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 208, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.362, y: 1 },
                o: { x: 0.149, y: 0.503 },
                t: 357,
                s: [9.667, -3.282, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.3, y: 0.3 },
                o: { x: 0.167, y: 0.167 },
                t: 371,
                s: [53.875, -26.75, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.85, y: 1 },
                o: { x: 0.7, y: 0 },
                t: 389,
                s: [53.875, -26.75, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              { t: 403, s: [2.5, -0.25, 0] },
            ],
            ix: 2,
            l: 2,
          },
          a: { a: 0, k: [-105, 238.125, 0], ix: 1, l: 2 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.3, 0.3, 0.3], y: [1, 1, 1] },
                o: { x: [0.15, 0.15, 0.15], y: [0, 0, 0] },
                t: 357,
                s: [10, 10, 100],
              },
              {
                i: { x: [0.3, 0.3, 0.3], y: [1, 1, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                t: 371,
                s: [100, 100, 100],
              },
              {
                i: { x: [0.85, 0.85, 0.85], y: [1, 1, 1] },
                o: { x: [0.7, 0.7, 0.7], y: [0, 0, 0] },
                t: 389,
                s: [100, 100, 100],
              },
              { t: 403, s: [20, 20, 100] },
            ],
            ix: 6,
            l: 2,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [15, 10],
                      [-21.5, -13.25],
                      [-11.5, 15],
                    ],
                    o: [
                      [-7.25, 13.5],
                      [26.732, 16.474],
                      [-18.25, -11],
                    ],
                    v: [
                      [-138, 205.75],
                      [-126.5, 261.25],
                      [-65.25, 250],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "st",
                c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                o: { a: 0, k: 75, ix: 4 },
                w: { a: 0, k: 0, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: "Stroke 1",
                mn: "ADBE Vector Graphic - Stroke",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.992156922583, 0.866666726505, 0.109803929048, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [0, 0], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Shape 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 356,
        op: 408,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 3,
        ty: 4,
        nm: "Hulf_Sircle",
        parent: 1,
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.367, y: 1 },
                o: { x: 0.149, y: 0.232 },
                t: 357,
                s: [-5.11, 10.786, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.3, y: 0.3 },
                o: { x: 0.167, y: 0.167 },
                t: 370,
                s: [-158, 173, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.85, y: 1 },
                o: { x: 0.7, y: 0 },
                t: 389,
                s: [-158, 173, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              { t: 402, s: [-0.5, 3.75, 0] },
            ],
            ix: 2,
            l: 2,
          },
          a: { a: 0, k: [-105, 238.125, 0], ix: 1, l: 2 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.3, 0.3, 0.3], y: [1, 1, 1] },
                o: { x: [0.15, 0.15, 0.15], y: [0, 0, 0] },
                t: 357,
                s: [10, 10, 100],
              },
              {
                i: { x: [0.3, 0.3, 0.3], y: [1, 1, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                t: 370,
                s: [100, 100, 100],
              },
              {
                i: { x: [0.85, 0.85, 0.85], y: [1, 1, 1] },
                o: { x: [0.7, 0.7, 0.7], y: [0, 0, 0] },
                t: 389,
                s: [100, 100, 100],
              },
              { t: 402, s: [20, 20, 100] },
            ],
            ix: 6,
            l: 2,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [15, 10],
                      [-21.5, -13.25],
                      [-11.5, 15],
                    ],
                    o: [
                      [-7.25, 13.5],
                      [26.732, 16.474],
                      [-18.25, -11],
                    ],
                    v: [
                      [-138, 205.75],
                      [-126.5, 261.25],
                      [-65.25, 250],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "st",
                c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                o: { a: 0, k: 75, ix: 4 },
                w: { a: 0, k: 0, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: "Stroke 1",
                mn: "ADBE Vector Graphic - Stroke",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.992156922583, 0.866666726505, 0.109803929048, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [0, 0], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Shape 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 356,
        op: 408,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 4,
        ty: 4,
        nm: "Ellips 2",
        parent: 1,
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 180, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.386, y: 1 },
                o: { x: 0.153, y: 0.231 },
                t: 357,
                s: [-12.006, -3.26, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.3, y: 0.3 },
                o: { x: 0.167, y: 0.167 },
                t: 367,
                s: [-178, -13, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.85, y: 1 },
                o: { x: 0.7, y: 0 },
                t: 389,
                s: [-178, -13, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              { t: 399, s: [1.25, 2, 0] },
            ],
            ix: 2,
            l: 2,
          },
          a: { a: 0, k: [119, 218, 0], ix: 1, l: 2 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.3, 0.3, 0.3], y: [1, 1, 1] },
                o: { x: [0.15, 0.15, 0.15], y: [0, 0, 0] },
                t: 357,
                s: [10, 10, 100],
              },
              {
                i: { x: [0.3, 0.3, 0.3], y: [1, 1, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                t: 367,
                s: [100, 100, 100],
              },
              {
                i: { x: [0.85, 0.85, 0.85], y: [1, 1, 1] },
                o: { x: [0.7, 0.7, 0.7], y: [0, 0, 0] },
                t: 389,
                s: [100, 100, 100],
              },
              { t: 399, s: [20, 20, 100] },
            ],
            ix: 6,
            l: 2,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [7.125, 5.375],
                      [5.25, -5.375],
                      [9.375, -7.875],
                      [5.625, 4],
                      [7, -6.625],
                      [-27.75, 24.5],
                    ],
                    o: [
                      [-3.125, 3.125],
                      [7.625, 5.375],
                      [-12.727, 10.69],
                      [-4, 3.875],
                      [11.5, 9.75],
                      [27.763, -24.512],
                    ],
                    v: [
                      [144.875, 179.375],
                      [128.875, 195.25],
                      [128.875, 228],
                      [96, 227.875],
                      [80.25, 243.375],
                      [143.875, 244],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "st",
                c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                o: { a: 0, k: 75, ix: 4 },
                w: { a: 0, k: 0, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: "Stroke 1",
                mn: "ADBE Vector Graphic - Stroke",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.992156922583, 0.866666726505, 0.109803929048, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [0, 0], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Shape 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 356,
        op: 408,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 5,
        ty: 4,
        nm: "Ellips",
        parent: 1,
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.372, y: 1 },
                o: { x: 0.15, y: 0.332 },
                t: 357,
                s: [12.493, 11.021, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.3, y: 0.3 },
                o: { x: 0.167, y: 0.167 },
                t: 369,
                s: [66, 153, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.85, y: 1 },
                o: { x: 0.7, y: 0 },
                t: 389,
                s: [66, 153, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              { t: 401, s: [1, 2.5, 0] },
            ],
            ix: 2,
            l: 2,
          },
          a: { a: 0, k: [119, 218, 0], ix: 1, l: 2 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.3, 0.3, 0.3], y: [1, 1, 1] },
                o: { x: [0.15, 0.15, 0.15], y: [0, 0, 0] },
                t: 357,
                s: [10, 10, 100],
              },
              {
                i: { x: [0.3, 0.3, 0.3], y: [1, 1, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                t: 369,
                s: [100, 100, 100],
              },
              {
                i: { x: [0.85, 0.85, 0.85], y: [1, 1, 1] },
                o: { x: [0.7, 0.7, 0.7], y: [0, 0, 0] },
                t: 389,
                s: [100, 100, 100],
              },
              { t: 401, s: [20, 20, 100] },
            ],
            ix: 6,
            l: 2,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [7.125, 5.375],
                      [5.25, -5.375],
                      [9.375, -7.875],
                      [5.625, 4],
                      [7, -6.625],
                      [-27.75, 24.5],
                    ],
                    o: [
                      [-3.125, 3.125],
                      [7.625, 5.375],
                      [-12.727, 10.69],
                      [-4, 3.875],
                      [11.5, 9.75],
                      [27.763, -24.512],
                    ],
                    v: [
                      [144.875, 179.375],
                      [128.875, 195.25],
                      [128.875, 228],
                      [96, 227.875],
                      [80.25, 243.375],
                      [143.875, 244],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "st",
                c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                o: { a: 0, k: 75, ix: 4 },
                w: { a: 0, k: 0, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: "Stroke 1",
                mn: "ADBE Vector Graphic - Stroke",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.992156922583, 0.866666726505, 0.109803929048, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [0, 0], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Shape 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 356,
        op: 408,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 6,
        ty: 4,
        nm: "Triangle 2",
        parent: 1,
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: -92, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.278, y: 1 },
                o: { x: 0.126, y: 0.219 },
                t: 357,
                s: [-17.265, 1.189, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.2, y: 0.2 },
                o: { x: 0.167, y: 0.167 },
                t: 372,
                s: [-352, 46, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.85, y: 1 },
                o: { x: 0.8, y: 0 },
                t: 389,
                s: [-352, 46, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              { t: 404, s: [1, 0.25, 0] },
            ],
            ix: 2,
            l: 2,
          },
          a: { a: 0, k: [296, 126.5, 0], ix: 1, l: 2 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.3, 0.3, 0.3], y: [1, 1, 1] },
                o: { x: [0.15, 0.15, 0.15], y: [0, 0, 0] },
                t: 357,
                s: [10, 10, 100],
              },
              {
                i: { x: [0.3, 0.3, 0.3], y: [1, 1, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                t: 372,
                s: [100, 100, 100],
              },
              {
                i: { x: [0.85, 0.85, 0.85], y: [1, 1, 1] },
                o: { x: [0.7, 0.7, 0.7], y: [0, 0, 0] },
                t: 389,
                s: [100, 100, 100],
              },
              { t: 404, s: [20, 20, 100] },
            ],
            ix: 6,
            l: 2,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [312.5, 88.5],
                      [258.25, 143.5],
                      [332.5, 163.75],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "st",
                c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                o: { a: 0, k: 75, ix: 4 },
                w: { a: 0, k: 0, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: "Stroke 1",
                mn: "ADBE Vector Graphic - Stroke",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.992156922583, 0.866666726505, 0.109803929048, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [0, 0], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Shape 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 356,
        op: 408,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 7,
        ty: 4,
        nm: "Triangle",
        parent: 1,
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.268, y: 1 },
                o: { x: 0.127, y: 0.279 },
                t: 357,
                s: [18.094, 1.29, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.2, y: 0.2 },
                o: { x: 0.167, y: 0.167 },
                t: 374,
                s: [242, 61, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              {
                i: { x: 0.85, y: 1 },
                o: { x: 0.8, y: 0 },
                t: 389,
                s: [242, 61, 0],
                to: [0, 0, 0],
                ti: [0, 0, 0],
              },
              { t: 406, s: [2, 2, 0] },
            ],
            ix: 2,
            l: 2,
          },
          a: { a: 0, k: [296, 126.5, 0], ix: 1, l: 2 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.3, 0.3, 0.3], y: [1, 1, 1] },
                o: { x: [0.15, 0.15, 0.15], y: [0, 0, 0] },
                t: 357,
                s: [10, 10, 100],
              },
              {
                i: { x: [0.3, 0.3, 0.3], y: [1, 1, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                t: 374,
                s: [100, 100, 100],
              },
              {
                i: { x: [0.85, 0.85, 0.85], y: [1, 1, 1] },
                o: { x: [0.7, 0.7, 0.7], y: [0, 0, 0] },
                t: 389,
                s: [100, 100, 100],
              },
              { t: 406, s: [20, 20, 100] },
            ],
            ix: 6,
            l: 2,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [312.5, 88.5],
                      [258.25, 143.5],
                      [332.5, 163.75],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "st",
                c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                o: { a: 0, k: 75, ix: 4 },
                w: { a: 0, k: 0, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: "Stroke 1",
                mn: "ADBE Vector Graphic - Stroke",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.992156922583, 0.866666726505, 0.109803929048, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [0, 0], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Shape 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 356,
        op: 408,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 8,
        ty: 4,
        nm: "Shape Layer 1",
        parent: 1,
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [1.25, 1.75, 0], ix: 2, l: 2 },
          a: { a: 0, k: [275, 11, 0], ix: 1, l: 2 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 353,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 357,
                s: [100, 100, 100],
              },
              { t: 358, s: [0, 0, 100] },
            ],
            ix: 6,
            l: 2,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: { a: 0, k: [54, 54], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.992156922583, 0.866666726505, 0.109803929048, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [275, 11], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 353,
        op: 359,
        st: 33,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 9,
        ty: 4,
        nm: "Vector 5",
        parent: 1,
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: {
            a: 1,
            k: [
              {
                i: { x: [0.3], y: [1] },
                o: { x: [0.167], y: [0.167] },
                t: 415,
                s: [0],
              },
              { t: 429, s: [180] },
            ],
            ix: 10,
          },
          p: { a: 0, k: [-4, -17.075, 0], ix: 2, l: 2 },
          a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 400,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 406,
                s: [7, 7, 100],
              },
              {
                i: { x: [0.3, 0.3, 0.3], y: [1, 1, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                t: 415,
                s: [7, 7, 100],
              },
              { t: 429, s: [0, 0, 100] },
            ],
            ix: 6,
            l: 2,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-55.026, 60.293],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-37.401, 26.387],
                      [-28.313, 33.143],
                      [-15.533, 39.513],
                      [0.743, 46.234],
                      [27.62, 54.571],
                      [43.788, 32.48],
                      [53.93, 14.172],
                      [56.889, 0.007],
                      [54.3, -14.113],
                      [45.214, -32.087],
                      [27.051, -47.96],
                      [-1.886, -62.137],
                      [-28.897, -56.849],
                      [-45.678, -44.722],
                      [37.919, -25.89],
                      [29.359, -34.432],
                      [16.165, -43.394],
                      [-0.478, -50.468],
                      [-32.29, -63.633],
                      [-50.322, -38.819],
                      [-63.279, -17.417],
                      [-67.657, -0.074],
                      [-76.301, 31.311],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [40.621, -21.39],
                      [35.916, -25.189],
                      [27.638, -32.629],
                      [16.429, -43.378],
                      [1.574, -60.832],
                      [-24.809, -47.875],
                      [-44.703, -32.643],
                      [-54.725, -15.147],
                      [-56.196, 0.274],
                      [-54.059, 13.975],
                      [-45.186, 32.486],
                      [-29.845, 54.937],
                      [-0.861, 63.439],
                      [29.152, 56.349],
                      [-42.03, 18.921],
                      [-37.815, 25.413],
                      [-29.778, 35.899],
                      [-17.369, 47.546],
                      [-2.11, 70.963],
                      [28.829, 55.888],
                      [51.54, 39.801],
                      [65.09, 17.995],
                      [82.696, 3.777],
                      [76.301, -31.311],
                    ],
                    v: [
                      [198.812, 626.647],
                      [341.505, 775.404],
                      [782.5, 774.818],
                      [407.594, 394.142],
                      [732.933, -52.42],
                      [345.109, -52.42],
                      [194.907, 185.063],
                      [5.951, -12.596],
                      [123.11, -84.338],
                      [219.84, -172.187],
                      [285.028, -281.118],
                      [308.76, -416.698],
                      [269.107, -592.394],
                      [164.866, -714.503],
                      [15.564, -785.368],
                      [-152.663, -808.208],
                      [-319.088, -786.539],
                      [-469.29, -716.846],
                      [-578.939, -594.737],
                      [-621.596, -416.112],
                      [-578.939, -233.095],
                      [-465.986, -80.532],
                      [-586.148, -13.181],
                      [-687.385, 77.01],
                      [-756.779, 196.776],
                      [-782.313, 344.947],
                      [-736.351, 549.926],
                      [-616.189, 693.705],
                      [-442.254, 780.382],
                      [-242.184, 807.615],
                      [-0.671, 765.791],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-16.878, 18.038],
                      [-41.82, 16.88],
                      [0, 0],
                      [38.338, -21.854],
                      [43.755, 1.032],
                      [23.811, 8.75],
                      [22.63, 46.01],
                      [-0.503, 28.22],
                      [-8.26, 23.049],
                    ],
                    o: [
                      [31.951, -31.253],
                      [0, 0],
                      [-24.729, 35.96],
                      [-38.538, 20.223],
                      [-25.447, 0.038],
                      [-49.195, -17.433],
                      [-12.36, -25.524],
                      [-1.684, -24.359],
                      [8.26, -23.049],
                    ],
                    v: [
                      [-385.778, 197.069],
                      [-274.027, 124.155],
                      [-7.867, 407.905],
                      [-103.697, 495.753],
                      [-229.266, 525.036],
                      [-303.767, 511.859],
                      [-415.818, 412.883],
                      [-433.843, 331.184],
                      [-423.875, 259.337],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "Path 2",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 2,
                ty: "sh",
                ix: 3,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [8.78, -20.59],
                      [16.405, -15.54],
                      [46.087, -18.982],
                      [21.927, 36.656],
                      [0.466, 36.807],
                      [-7.083, 17.377],
                      [-13.55, 13.235],
                      [-17.812, 6.94],
                      [-19.176, -0.485],
                      [-18.055, -7.131],
                      [-13.298, -13.877],
                      [0.95, -36.92],
                    ],
                    o: [
                      [0.272, 22.297],
                      [-8.78, 20.59],
                      [-35.465, 34.401],
                      [-33.698, -26.861],
                      [-19.216, -31.688],
                      [-0.537, -18.691],
                      [7.083, -17.377],
                      [13.55, -13.235],
                      [17.812, -6.94],
                      [19.457, -0.94],
                      [18.055, 7.131],
                      [25.44, 27.367],
                      [0, 0],
                    ],
                    v: [
                      [-4.563, -405.277],
                      [-17.458, -340.273],
                      [-55.632, -285.511],
                      [-179.098, -204.69],
                      [-263.212, -300.738],
                      [-293.253, -405.277],
                      [-283.331, -459.947],
                      [-252.058, -506.348],
                      [-204.521, -536.929],
                      [-148.457, -546.713],
                      [-91.512, -537.315],
                      [-43.916, -505.424],
                      [-5.764, -405.277],
                    ],
                    c: false,
                  },
                  ix: 2,
                },
                nm: "Path 3",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "mm",
                mm: 1,
                nm: "Merge Paths 1",
                mn: "ADBE Vector Filter - Merge",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.992156863213, 0.866666674614, 0.109803922474, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [0, 0], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Vector",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 397,
        op: 430,
        st: 1,
        bm: 4,
      },
      {
        ddd: 0,
        ind: 10,
        ty: 4,
        nm: "Shape Layer 7",
        parent: 1,
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [-5.75, -17.75, 0], ix: 2, l: 2 },
          a: { a: 0, k: [275, 11, 0], ix: 1, l: 2 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                t: 415,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                t: 424,
                s: [100, 100, 100],
              },
              { t: 434, s: [0, 0, 100] },
            ],
            ix: 6,
            l: 2,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                d: 1,
                ty: "el",
                s: { a: 0, k: [54, 54], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: "Ellipse Path 1",
                mn: "ADBE Vector Shape - Ellipse",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.992156922583, 0.866666726505, 0.109803929048, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [275, 11], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Ellipse 1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 415,
        op: 435,
        st: 33,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 11,
        ty: 4,
        nm: "Ampersand 3",
        parent: 1,
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                t: 492,
                s: [100],
              },
              { t: 495, s: [0] },
            ],
            ix: 11,
          },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [3.625, -8.25, 0], ix: 2, l: 2 },
          a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 190,
                s: [11, 11, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                t: 220,
                s: [7, 7, 100],
              },
              {
                i: { x: [0.319, 0.319, 0.319], y: [0.92, 0.92, 4.334] },
                o: { x: [0.176, 0.176, 0.176], y: [0.169, 0.169, -7.04] },
                t: 339,
                s: [7, 7, 100],
              },
              {
                i: { x: [0.653, 0.653, 0.653], y: [1, 1, 1] },
                o: { x: [0.32, 0.32, 0.32], y: [0.012, 0.012, -0.196] },
                t: 355,
                s: [2, 2, 100],
              },
              { t: 357, s: [0, 0, 100] },
            ],
            ix: 6,
            l: 2,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-55.026, 60.293],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-37.401, 26.387],
                      [-28.313, 33.143],
                      [-15.533, 39.513],
                      [0.743, 46.234],
                      [27.62, 54.571],
                      [43.788, 32.48],
                      [53.93, 14.172],
                      [56.889, 0.007],
                      [54.3, -14.113],
                      [45.214, -32.087],
                      [27.051, -47.96],
                      [-1.886, -62.137],
                      [-28.897, -56.849],
                      [-45.678, -44.722],
                      [37.919, -25.89],
                      [29.359, -34.432],
                      [16.165, -43.394],
                      [-0.478, -50.468],
                      [-32.29, -63.633],
                      [-50.322, -38.819],
                      [-63.279, -17.417],
                      [-67.657, -0.074],
                      [-76.301, 31.311],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [40.621, -21.39],
                      [35.916, -25.189],
                      [27.638, -32.629],
                      [16.429, -43.378],
                      [1.574, -60.832],
                      [-24.809, -47.875],
                      [-44.703, -32.643],
                      [-54.725, -15.147],
                      [-56.196, 0.274],
                      [-54.059, 13.975],
                      [-45.186, 32.486],
                      [-29.845, 54.937],
                      [-0.861, 63.439],
                      [29.152, 56.349],
                      [-42.03, 18.921],
                      [-37.815, 25.413],
                      [-29.778, 35.899],
                      [-17.369, 47.546],
                      [-2.11, 70.963],
                      [28.829, 55.888],
                      [51.54, 39.801],
                      [65.09, 17.995],
                      [82.696, 3.777],
                      [76.301, -31.311],
                    ],
                    v: [
                      [198.812, 626.647],
                      [341.505, 775.404],
                      [782.5, 774.818],
                      [407.594, 394.142],
                      [732.933, -52.42],
                      [345.109, -52.42],
                      [194.907, 185.063],
                      [5.951, -12.596],
                      [123.11, -84.338],
                      [219.84, -172.187],
                      [285.028, -281.118],
                      [308.76, -416.698],
                      [269.107, -592.394],
                      [164.866, -714.503],
                      [15.564, -785.368],
                      [-152.663, -808.208],
                      [-319.088, -786.539],
                      [-469.29, -716.846],
                      [-578.939, -594.737],
                      [-621.596, -416.112],
                      [-578.939, -233.095],
                      [-465.986, -80.532],
                      [-586.148, -13.181],
                      [-687.385, 77.01],
                      [-756.779, 196.776],
                      [-782.313, 344.947],
                      [-736.351, 549.926],
                      [-616.189, 693.705],
                      [-442.254, 780.382],
                      [-242.184, 807.615],
                      [-0.671, 765.791],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "Path 1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-16.878, 18.038],
                      [-41.82, 16.88],
                      [0, 0],
                      [38.338, -21.854],
                      [43.755, 1.032],
                      [23.811, 8.75],
                      [22.63, 46.01],
                      [-0.503, 28.22],
                      [-8.26, 23.049],
                    ],
                    o: [
                      [31.951, -31.253],
                      [0, 0],
                      [-24.729, 35.96],
                      [-38.538, 20.223],
                      [-25.447, 0.038],
                      [-49.195, -17.433],
                      [-12.36, -25.524],
                      [-1.684, -24.359],
                      [8.26, -23.049],
                    ],
                    v: [
                      [-385.778, 197.069],
                      [-274.027, 124.155],
                      [-7.867, 407.905],
                      [-103.697, 495.753],
                      [-229.266, 525.036],
                      [-303.767, 511.859],
                      [-415.818, 412.883],
                      [-433.843, 331.184],
                      [-423.875, 259.337],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "Path 2",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 2,
                ty: "sh",
                ix: 3,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [8.78, -20.59],
                      [16.405, -15.54],
                      [46.087, -18.982],
                      [21.927, 36.656],
                      [0.466, 36.807],
                      [-7.083, 17.377],
                      [-13.55, 13.235],
                      [-17.812, 6.94],
                      [-19.176, -0.485],
                      [-18.055, -7.131],
                      [-13.298, -13.877],
                      [0.95, -36.92],
                    ],
                    o: [
                      [0.272, 22.297],
                      [-8.78, 20.59],
                      [-35.465, 34.401],
                      [-33.698, -26.861],
                      [-19.216, -31.688],
                      [-0.537, -18.691],
                      [7.083, -17.377],
                      [13.55, -13.235],
                      [17.812, -6.94],
                      [19.457, -0.94],
                      [18.055, 7.131],
                      [25.44, 27.367],
                      [0, 0],
                    ],
                    v: [
                      [-4.563, -405.277],
                      [-17.458, -340.273],
                      [-55.632, -285.511],
                      [-179.098, -204.69],
                      [-263.212, -300.738],
                      [-293.253, -405.277],
                      [-283.331, -459.947],
                      [-252.058, -506.348],
                      [-204.521, -536.929],
                      [-148.457, -546.713],
                      [-91.512, -537.315],
                      [-43.916, -505.424],
                      [-5.764, -405.277],
                    ],
                    c: false,
                  },
                  ix: 2,
                },
                nm: "Path 3",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ty: "mm",
                mm: 1,
                nm: "Merge Paths 1",
                mn: "ADBE Vector Filter - Merge",
                hd: false,
              },
              {
                ty: "fl",
                c: {
                  a: 0,
                  k: [0.992156863213, 0.866666674614, 0.109803922474, 1],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: "Fill 1",
                mn: "ADBE Vector Graphic - Fill",
                hd: false,
              },
              {
                ty: "tr",
                p: { a: 0, k: [0, 0], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: "Transform",
              },
            ],
            nm: "Vector",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
        ip: 173,
        op: 361,
        st: -10,
        bm: 4,
      },
    ],
    markers: [],
  },
});

const playLottieAnimation = () => {
  animationInline.setDirection(1);
  animationInline.play();
};
const playLottieAnimationReverse = () => {
  animationInline.setDirection(-1);
  animationInline.play();
};

// Initialize Lottie animation

const bannerAnimation = () => {
  if (windowWidth >= 992 && windowWidth < 1920) {
    const ampersand = gsap.timeline();
    const tl = gsap
      .timeline({
        defaults: { duration: 2, ease: "none" },
        scrollTrigger: {
          trigger: ".banner__inner",
          start: "50px",
          end: "1500px",
          scrub: true,
          // markers: true,
          onEnterBack: () => {
            gsap.to(
              "#lottie svg",
              {
                opacity: 0,
              },
              0
            );
            playLottieAnimationReverse();
            gsap.to(
              "#ampersand-lapt",
              {
                opacity: 0,
              },
              0
            );
          },
          onUpdate: ({ direction, progress }) => {
            // console.log(progress);
            gsap.set("#ampersand-lapt", {
              scaleX: 1,
              transformOrigin: "50% 50%",
              rotate: "0",
            });

            const motionPathProgress = progress * 0.2; // Assuming 25% of the motion path

            if (progress * 100 < 50 && progress * 100 >= 17) {
              gsap.to(
                "#ampersand-lapt",
                {
                  rotation: motionPathProgress * 360,
                  transformOrigin: "50% 50%",
                },
                0
              );
            }

            if (progress * 100 < 17) {
              gsap.to(
                "#ampersand-lapt",
                {
                  rotation: 0, // Rotate 360 degrees for a full circle
                  transformOrigin: "50% 50%",
                },
                0
              );
            }

            if (progress * 100 > 64.5 && progress * 100 <= 71.7) {
              ampersand.to("#ampersand-lapt", { rotate: 12 }, 0);
            }
            if (progress * 100 > 71.7 && progress * 100 <= 78.8) {
              ampersand.to("#ampersand-lapt", { rotate: -8 }, 0);
            }
            if (progress * 100 > 78.8 && progress * 100 <= 84.8) {
              ampersand.to("#ampersand-lapt", { rotate: 6 }, 0);
            }
            if (progress * 100 > 84.8 && progress * 100 <= 90.4) {
              ampersand.to("#ampersand-lapt", { rotate: -20 }, 0);
            }
            if (progress * 100 > 90.4 && progress * 100 <= 100) {
              ampersand.to("#ampersand-lapt", { rotate: 0, scale: 1 }, 0);
            }

            // console.log(direction);

            if (progress * 100 < 2) {
              setTimeout(() => {
                ampersand.to("#ampersand-lapt", { opacity: 1 }, 0);
              }, 1000);
            } else {
              // console.log("backward ⬆");
            }
          },
        },
      })
      .to("#ampersand-lapt", {
        motionPath: {
          path: "#path-lapt",
          align: "#path-lapt",
          autoRotate: true,
          alignOrigin: [0.5, 0.5],
        },
      })
      .eventCallback("onComplete", () => {
        gsap.to("#ampersand-lapt", {
          scale: 0.3,
          delay: 2,
        });
        setTimeout(() => {
          gsap.to(
            "#ampersand-lapt",
            {
              opacity: 0,
              // delay: 2,
            },
            0
          );
          gsap.to(
            "#lottie svg",
            {
              opacity: "1",
              // delay: 2,
            },
            0
          );
          playLottieAnimation();
        }, 100);
      });
  }

  if (windowWidth >= 1920) {
    const ampersand = gsap.timeline();

    const tld = gsap
      .timeline({
        defaults: { duration: 2, ease: "none" },
        scrollTrigger: {
          trigger: ".banner__inner",
          start: "50px",
          end: "1800px",
          scrub: true,
          // markers: true,
          onEnterBack: () => {
            gsap.to(
              "#lottie svg",
              {
                opacity: 0,
              },
              0
            );
            playLottieAnimationReverse();
            gsap.to(
              "#ampersand-desk",
              {
                opacity: 0,
              },
              0
            );
          },
          onUpdate: ({ direction, progress }) => {
            // console.log(progress);
            gsap.set("#ampersand-desk", {
              scaleX: 1,
              transformOrigin: "50% 50%",
              rotate: "0",
            });

            const motionPathProgress = progress * 0.2;

            if (progress * 100 < 50 && progress * 100 >= 17) {
              gsap.to(
                "#ampersand-desk",
                {
                  rotation: motionPathProgress * 360,
                  transformOrigin: "50% 50%",
                },
                0
              );
            }

            if (progress * 100 < 17) {
              gsap.to(
                "#ampersand-desk",
                {
                  rotation: 0,
                  transformOrigin: "50% 50%",
                },
                0
              );
            }

            if (progress * 100 > 64.5 && progress * 100 <= 71.7) {
              ampersand.to("#ampersand-desk", { rotate: 12 }, 0);
            }
            if (progress * 100 > 71.7 && progress * 100 <= 78.8) {
              ampersand.to("#ampersand-desk", { rotate: -8 }, 0);
            }
            if (progress * 100 > 78.8 && progress * 100 <= 84.8) {
              ampersand.to("#ampersand-desk", { rotate: 6 }, 0);
            }
            if (progress * 100 > 84.8 && progress * 100 <= 90.4) {
              ampersand.to("#ampersand-desk", { rotate: -20 }, 0);
            }
            if (progress * 100 > 90.4 && progress * 100 <= 100) {
              ampersand.to("#ampersand-desk", { rotate: 0, scale: 1 }, 0);
            }

            // console.log(direction);

            if (progress * 100 < 2) {
              setTimeout(() => {
                ampersand.to("#ampersand-desk", { opacity: 1 }, 0);
              }, 1000);
            } else {
              // console.log("backward ⬆");
            }
          },
        },
      })
      .to("#ampersand-desk", {
        motionPath: {
          path: "#path-desk",
          align: "#path-desk",
          autoRotate: true,
          alignOrigin: [0.5, 0.5],
        },
      })
      .eventCallback("onComplete", () => {
        gsap.to("#ampersand-desk", {
          scale: 0.3,
        });
        setTimeout(() => {
          gsap.to(
            "#ampersand-desk",
            {
              opacity: 0,
            },
            0
          );
          gsap.to(
            "#lottie svg",
            {
              opacity: "1",
            },
            0
          );
          playLottieAnimation();
        }, 100);
      });
  }

  if (windowWidth >= 992) {
    const tls = gsap.timeline({
      scrollTrigger: {
        trigger: ".l-blocks-wrap",
        start: "10px",
        end: "-100px",
        scrub: 3,
      },
    });

    tls
      .to(".banner__bg", {
        background: "transparent",
        scale: 2,
        duration: 2,
      })
      .to(
        ".ampersand",
        {
          duration: 10,
          scale: 2,
          opacity: 0,
          top: "50%",
          left: "50%",
        },
        0
      )
      .to(
        "h1 span:nth-of-type(1)",
        {
          x: "-100vw",
          duration: 1,
        },
        0
      )
      .to(
        "h1 span:nth-of-type(2)",
        {
          x: "100vw",
          duration: 1,
        },
        0
      )
      .to(
        "#lottie",
        {
          position: "fixed",
          top: "50%",
          left: "50%",
          margin: 0,
          scale: 1,
          duration: 1.5,
          opacity: 1,
        },
        0
      )
      .fromTo(
        "#ampersand-lapt",
        {
          scale: 0,
        },
        {
          duration: 3,
          scale: 1,
        }
      )
      .fromTo(
        "#ampersand-desk",
        {
          scale: 0,
        },
        {
          duration: 3,
          scale: 1,
        }
      );

    const titleAnimation1Start = gsap.from("h1 span:nth-of-type(1)", {
      x: "-100vw",
      duration: 1,
      delay: 3,
    });

    const titleAnimation2Start = gsap.from("h1 span:nth-of-type(2)", {
      x: "100vw",
      duration: 1,
      delay: 3,
    });
  }

  // Text animation for span in picture text block

  //register the plugin (just once)

  const spans = document.querySelectorAll(".block-text-picture-home h2 span");
  spans.forEach((span, i) => {
    gsap.from(span, {
      opacity: 0,
      y: "100px",
      scrollTrigger: {
        trigger: span,
        start: "-=" + window.innerHeight,
        // start: "-=1000px",
        end: "bottom bottom",
        scrub: 1,
        // markers: true,
      },
    });
  });
};

document.addEventListener("DOMContentLoaded", function () {
  bannerAnimation();
});
